import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';

export default (uid, token) => (dispatch) => {
  dispatch({
    types: [
      'CONFIRM_EDIT_EMAIL_REQUEST',
      'CONFIRM_EDIT_EMAIL_SUCCESS',
      'CONFIRM_EDIT_EMAIL_FAILURE',
    ],
    config: {
      method: 'POST',
      url: 'email-edit/confirm/',
      data: {
        uid,
        token,
      },
    },
    callback: (success, result) => {
      if (success) {
        dispatch({type: 'SIGN_OUT_COMPLETE'});
        const message = result && result.data && result.data.message;
        notification({
          message,
          type: 'success',
        });
        navigate('/auth/login');
      } else if (result.response && result.response.status >= 400) {
        const errors = Object.values(result.response.data);
        const message = errors && errors[0] && errors[0].toString();
        notification({
          message,
        });
        navigate('/');
      }
    },
  });
};

import LoadingIndicator from 'modules/common/LoadingIndicator';
import React from 'react';
import {connect} from 'react-redux';
import {compose, lifecycle, withProps} from 'recompose';
import confirmEditEmail from '../actions/confirm-edit-email';

const ResetEmail = ({onConfirm, email}) => <LoadingIndicator />;

const enhance = compose(
  connect(null),
  withProps(({location: {search}}) =>
    search
      .substr(1)
      .split('&')
      .map((keyValue) => keyValue.split('='))
      .reduce(
        (params, keyValue) => ({
          ...params,
          [keyValue[0]]: keyValue[1],
        }),
        {},
      ),
  ),
  lifecycle({
    componentDidMount() {
      const {dispatch, uid, token} = this.props;
      dispatch(confirmEditEmail(uid, token));
    },
  }),
);

export default enhance(ResetEmail);
